<template>
  <div class="aa content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="搜索">
              <el-input
                @clear="handleSearch"
                v-model="ProductName"
                placeholder="输入顾客姓名/手机号/编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="任务类型">
              <el-select placeholder="请选择">
                <el-option label="全部" value="1"></el-option>
                <el-option label="生日" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务创建时间">
              <el-date-picker
                v-model="datePicker"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @clear="handleSearch"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-tabs v-model="activeName" @tab-click="handleClickTabs">
        <!-- 待处理 -->
        <el-tab-pane label="待处理" name="0">
          <el-table :data="pendingTableData" style="width: 100%">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="顾客" width="180">
              <template slot-scope="scope">
                <div class="dis_flex flex_y_center">
                  <span>{{ scope.row.customer.name }}</span>
                  <span class="member font_8 marlt_5">{{
                    scope.row.customer.member
                  }}</span>
                  <img
                    v-if="scope.row.customer.wxBool"
                    class="marlt_5"
                    style="height: 16px"
                    :src="require('../../../assets/img/wxlogo.png')"
                    alt=""
                  />
                </div>
                <div>{{ scope.row.customer.phone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="taskType" label="任务类型" width="180">
            </el-table-column>
            <el-table-column prop="taskSource" label="任务来源" width="180">
            </el-table-column>
            <el-table-column
              prop="taskCreatedTime"
              label="任务创建时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="taskCreatedPeople"
              label="任务创建人"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="followUpPeople" label="跟进人" width="180">
            </el-table-column>
            <el-table-column prop="followUpWay" label="跟进方式" width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="openDialog"
                  >处理</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div
            class="pad_10 dis_flex flex_x_between"
            v-if="paginations.total > 0"
          >
            <el-dropdown size="small" @command="handleCommand">
              <el-button type="primary" size="small" v-prevent-click>
                批量设置
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu trigger="click" slot="dropdown">
                <el-dropdown-item command="state">状态</el-dropdown-item>
                <el-dropdown-item command="onlineType"
                  >线上分类</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-pagination
              background
              :current-page.sync="paginations.page"
              :page-size="paginations.page_size"
              :page-sizes="[1, , 10, 15, 20, 30, 40, 50]"
              :layout="paginations.layout"
              :total="paginations.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已处理" name="1">
          <el-table :data="doneTableData" style="width: 100%">
            <el-table-column label="顾客" width="180">
              <template slot-scope="scope">
                <div class="dis_flex flex_y_center">
                  <span>{{ scope.row.customer.name }}</span>
                  <span class="member font_8 marlt_5">{{
                    scope.row.customer.member
                  }}</span>
                  <img
                    v-if="scope.row.customer.wxBool"
                    class="marlt_5"
                    style="height: 16px"
                    :src="require('../../../assets/img/wxlogo.png')"
                    alt=""
                  />
                </div>
                <div>{{ scope.row.customer.phone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="taskType" label="任务类型" width="180">
            </el-table-column>
            <el-table-column prop="taskSource" label="任务来源" width="180">
            </el-table-column>
            <el-table-column
              prop="taskCreatedTime"
              label="任务创建时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="taskCreatedPeople"
              label="任务创建人"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="followUpPeople" label="跟进人" width="180">
            </el-table-column>
            <el-table-column prop="fillTime" label="填写时间" width="180">
            </el-table-column>
            <el-table-column label="护理日志">
              <template slot-scope="scope">
                <div class="oneText">{{ scope.row.followUpCon }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已作废" name="2">
          <el-table :data="abandonTableData" style="width: 100%">
            <el-table-column label="顾客" width="220">
              <template slot-scope="scope">
                <div class="dis_flex flex_y_center">
                  <span>{{ scope.row.customer.name }}</span>
                  <span class="member font_8 marlt_5">{{
                    scope.row.customer.member
                  }}</span>
                  <img
                    v-if="scope.row.customer.wxBool"
                    class="marlt_5"
                    style="height: 16px"
                    :src="require('../../../assets/img/wxlogo.png')"
                    alt=""
                  />
                </div>
                <div>{{ scope.row.customer.phone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="taskType" label="任务类型">
            </el-table-column>
            <el-table-column prop="taskSource" label="任务来源">
            </el-table-column>
            <el-table-column prop="taskCreatedTime" label="任务创建时间">
            </el-table-column>
            <el-table-column
              prop="taskCreatedPeople"
              label="任务创建人"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="followUpPeople" label="跟进人" width="180">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="填写护理日志"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-row class="customer" :gutter="20">
        <div class="dis_flex flex_y_center">
          <el-col :span="2">
            <el-avatar :size="70" :src="circleUrl"></el-avatar>
          </el-col>
          <el-col :span="22">
            <div>
              <div class="padbm_15 color_333">
                <span>刘亦菲</span>
                <el-image
                  class="marlt_5"
                  style="width: 8px; height: 12px"
                  :src="require('../../../assets/img/gender-female.png')"
                  :fit="fit"
                ></el-image>
                <el-image
                  class="marlt_5"
                  style="width: 12px; height: 12px"
                  :src="require('../../../assets/img/wxlogo.png')"
                  :fit="fit"
                ></el-image>
              </div>
              <div class="dis_flex flex_wrap">
                <div style="width: 30%" class="padbm_10">
                  <span class="color_999">手机号：</span
                  ><span>13233868071</span>
                </div>
                <div style="width: 30%" class="padbm_10">
                  <span class="color_999">所属顾问：</span><span>张三</span>
                </div>
                <div style="width: 30%" class="padbm_10">
                  <span class="color_999">会员生日：</span
                  ><span>2021-03-09</span>
                </div>
                <div style="width: 30%" class="">
                  <span class="color_999">顾客来源：</span><span>自来</span>
                </div>
                <div style="width: 30%" class="">
                  <span class="color_999">顾客等级：</span><span>普通会员</span>
                </div>
                <div style="width: 30%" class="">
                  <span class="color_999">注册时间：</span
                  ><span>2018-11-10 16:50</span>
                </div>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
      <el-form ref="form" :model="form" label-width="120px" size="small">
        <el-form-item label="任务类型：">
          <span>消耗生成</span>
        </el-form-item>
        <el-form-item label="任务来源：">
          <span class="marrt_10">xh20210510100000</span>
          <el-button type="primary">详情</el-button>
        </el-form-item>
        <el-form-item label="护理日志：">
          <el-input type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="添加图片：">
          <el-upload action="#" list-type="picture-card" :auto-upload="false">
            <i slot="default" class="el-icon-camera-solid"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="samll">取 消</el-button>
        <el-button type="primary" size="samll" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "aa",
  data() {
    return {
      loading: false,
      datePicker: "",
      activeName: "0",
      pendingTableData: [
        {
          customer: {
            name: "张三",
            phone: "13233868071",
            member: "黄金会员",
            wxBool: true,
          },
          taskType: "生日",
          taskSource: "xh20210510100000",
          taskCreatedPeople: "阿廖007",
          followUpPeople: "啊廖",
          taskCreatedTime: "2021-05-10 10:00:00",
          followUpWay: "电话",
          followUpCon:
            "啦啦啦啦啦啊啦啦啦啦啦啦啦啦啦啦啦了了啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦",
        },
      ],
      doneTableData: [
        {
          customer: {
            name: "张三",
            phone: "13233868071",
            member: "黄金会员",
            wxBool: true,
          },
          taskType: "生日",
          taskSource: "xh20210510100000",
          taskCreatedPeople: "阿廖007",
          followUpPeople: "啊廖",
          taskCreatedTime: "2021-05-10 10:00:00",
          fillTime: "2021-02-10 11:00:00",
          followUpCon:
            "啦啦啦啦啦啊啦啦啦啦啦啦啦啦啦啦啦了了啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦",
        },
      ],
      abandonTableData: [
        {
          customer: {
            name: "张三",
            phone: "13233868071",
            member: "黄金会员",
            wxBool: true,
          },
          taskType: "生日",
          taskSource: "xh20210510100000",
          taskCreatedPeople: "阿廖007",
          followUpPeople: "啊廖",
          taskCreatedTime: "2021-05-10 10:00:00",
        },
      ],
      paginations: {
        total: 2,
        layout: "sizes, prev, pager, next, jumper, ->, total, slot",
        page_size: 1,
        page: 1,
      },
      dialogVisible: false,
    };
  },
  methods: {
    // 切换tabs
    handleClickTabs(e) {
      this.activeName = e.index;
    },
    // 打开弹出层
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>


<style  lang="scss">
.aa {
  .oneText {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .member {
    display: inline-block;
    border: 1px solid #409eff;
    line-height: 16px;
    padding: 0px 5px;
    color: #409eff;
  }
  .customer {
    height: 120px;
    padding-top: 20px;
    background-color: #f7f7f7;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  .el-upload.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 115px;
  }
}
</style>